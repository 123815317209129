<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-text>
          <v-row align="start" class="ml-2">
            <v-card-title>{{ $t("general.protocol") }}</v-card-title>
          </v-row>
          <v-row align="start" class="ml-2">
            <base-hover-button
              class=""
              color="primary lighten-5"
              text-color="primary--text"
              :text="$t('general.back')"
              @click.native="goBack()"
              iconName=" mdi-arrow-left-circle"
            />
            <v-spacer />
            <v-col class="d-flex" cols="11" sm="5">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('general.search')"
                single-line
                hide-details
              />
            </v-col>
          </v-row>
          <v-data-table
            v-model="selected"
            :search="search"
            :headers="headers"
            :items="La_Questions"
            item-key="i_TestID"
            class="elevation-1 table-one"
            multi-sort
            :loading="Lb_Isloded"
            :loading-text="$t('general.please_wait')"
            :footer-props="{
              'items-per-page-text': $t('general.questions_per_page'),
            }"
            :items-per-page=20
          >
            <template v-slot:item.s_Author="{ item }">
              <p class="ma-0 font-weight-medium">
                {{ item.s_Author }}
              </p>
            </template>
            <template v-slot:item.t_Date="{ item }">
              {{ formatDate(item.t_Date) }}
            </template>
            <template v-slot:item.i_Status="{ item }">
              <template v-if="item.i_Status == 3">
                <v-chip color="success" label small text-color="white">
                  <v-icon small left> mdi-check </v-icon>
                  {{ $t("general.definitely") }}
                </v-chip>
              </template>
              <template v-else-if="item.i_Status == 0">
                <v-chip color="warning" label small text-color="white">
                  {{ $t("general.draft") }}
                </v-chip>
              </template>
              <template v-else-if="item.i_Status == 1">
                <v-chip color="warning" label small text-color="white">
                  QSK
                </v-chip>
              </template>
              <template v-else-if="item.i_Status == 2">
                <v-chip color="warning" label small text-color="white">
                  {{ $t("general.in_translation") }}
                </v-chip>
              </template>
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mx-2"
                    fab
                    x-small
                    color="success"
                    v-bind="attrs"
                    v-on="on"
                    @click="routeToViewQuestionsHistory(item)"
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("general.show") }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import IconClassBar from "../../../components/base/IconClassBar.vue";
import { get } from "../../../worker/worker-api";
export default {
  name: "question-History-list",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: this.$t("general.question_protocol_list"),
    };
  },
  components: {
    IconClassBar,
  },
  props: {
    i_TestID: {
      type: Number,
    },
  },
  methods: {
    routeToViewQuestionsHistory(Ao_Question) {
      let Lo_QuestionSorted = Ao_Question
      const La_QuestionSorted = Ao_Question.a_Questions.sort((a, b) => a.i_Order > b.i_Order ? 1 : -1)
      Lo_QuestionSorted.a_Questions = La_QuestionSorted
      this.$router.push({
        name: "view-question",
        params: { o_Question: Lo_QuestionSorted },
      });
    },
    formatDate(date) {
      if (date) {
        let d = new Date(date);
        let month = (d.getMonth() + 1).toString().padStart(2, "0");
        let day = d.getDate().toString().padStart(2, "0");
        let year = d.getFullYear();
        return [day, month, year].join("-");
      } // if
      else {
        return "";
      } // else
    },
    getTestsHistorylist() {
      this.Lb_Isloded = true;
      get(`${process.env.VUE_APP_SMT_API_URL}/teacher/question-history-list`, {
        i_TestID: this.i_TestID,
      })
        .then((response) => {
          if (response.i_Status === 401) {
            this.signOut();
            this.$smt.logout();
            return;
          }
          this.La_Questions = response.a_TestQuestions.sort((a, b) => {
            const t_A = new Date(a.t_Date)
            const t_B = new Date(b.t_Date)
            return t_A > t_B ? -1 : 1
          });
          this.Lb_Isloded = false;
        })
        .catch((error) => {
          console.log("Worker error: ", error);
        });
    },
    goBack() {
      if (
        this.La_Questions.length !== 0 &&
        this.La_Questions[0].i_TestTypeID == 1
      ) {
        this.$router.push({ name: "ci-oral-questions" });
      } else if (
        this.La_Questions.length !== 0 &&
        this.La_Questions[0].i_TestTypeID == 2
      ) {
        this.$router.push({ name: "fg-oral-questions" });
      } else {
        this.$router.back();
      }
    },
  },
  created() {
    if (this.i_TestID) {
      this.getTestsHistorylist();
    } else {
      //go to the last page
      this.$router.back();
    }
  },
  data() {
    return {
      Lb_Isloded: false,
      search: "",
      selected: [],
      headers: [
        {
          text: this.$t("general.author"),
          align: "start",
          sortable: false,
          value: "s_Author",
        },
        { text: this.$t("general.date"), value: "t_Date" },
        { text: this.$t("general.status"), value: "i_Status" },
        { text: this.$t("general.actions"), value: "action" },
      ],
      La_Questions: [],
    };
  },
};
</script>
<style lang="scss" scoped>
.textarea-line-height {
}
::v-deep .table-one {
  thead.v-data-table-header {
    tr {
      &:hover {
        background-color: #f2f3f8;
      }
      th {
        span {
          font-size: 16px;
          color: #304156;
        }
      }
    }
    tr {
      td {
        padding-bottom: 20px;
        padding-top: 20px;
      }
    }
  }
  tbody {
    tr {
      &:hover {
        background-color: #f2f3f8 !important;
      }
    }
  }
}
</style>
