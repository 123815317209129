var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-card',[_c('v-card-text',[_c('v-row',{staticClass:"ml-2",attrs:{"align":"start"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("general.protocol")))])],1),_c('v-row',{staticClass:"ml-2",attrs:{"align":"start"}},[_c('base-hover-button',{attrs:{"color":"primary lighten-5","text-color":"primary--text","text":_vm.$t('general.back'),"iconName":" mdi-arrow-left-circle"},nativeOn:{"click":function($event){return _vm.goBack()}}}),_c('v-spacer'),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"11","sm":"5"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('general.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 table-one",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.La_Questions,"item-key":"i_TestID","multi-sort":"","loading":_vm.Lb_Isloded,"loading-text":_vm.$t('general.please_wait'),"footer-props":{
            'items-per-page-text': _vm.$t('general.questions_per_page'),
          },"items-per-page":20},scopedSlots:_vm._u([{key:"item.s_Author",fn:function(ref){
          var item = ref.item;
return [_c('p',{staticClass:"ma-0 font-weight-medium"},[_vm._v(" "+_vm._s(item.s_Author)+" ")])]}},{key:"item.t_Date",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.t_Date))+" ")]}},{key:"item.i_Status",fn:function(ref){
          var item = ref.item;
return [(item.i_Status == 3)?[_c('v-chip',{attrs:{"color":"success","label":"","small":"","text-color":"white"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-check ")]),_vm._v(" "+_vm._s(_vm.$t("general.definitely"))+" ")],1)]:(item.i_Status == 0)?[_c('v-chip',{attrs:{"color":"warning","label":"","small":"","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.$t("general.draft"))+" ")])]:(item.i_Status == 1)?[_c('v-chip',{attrs:{"color":"warning","label":"","small":"","text-color":"white"}},[_vm._v(" QSK ")])]:(item.i_Status == 2)?[_c('v-chip',{attrs:{"color":"warning","label":"","small":"","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.$t("general.in_translation"))+" ")])]:_vm._e()]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","x-small":"","color":"success"},on:{"click":function($event){return _vm.routeToViewQuestionsHistory(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.show")))])])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }